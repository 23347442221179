import React,{useState} from 'react';
import {Link} from 'gatsby';
import './Success.scss';


const Succsss = (props) =>{
  
  const changeType=props.changeType;

  return <div className="Success">
    <h3>提交成功！</h3>
    <p>组委会将在7个工作日内完成审核，并将审核结果发至您的手机。</p>
    <p>还可在<a onClick={e=>changeType('myEnroll')}>我的报名</a>查看已报名表格。 </p>
  </div>
}

export default Succsss;